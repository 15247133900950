<template>
  <div class="mb-3">
    <b-navbar toggleable="lg">
      <b-navbar-brand href="/">
        <img class="logo" src="../assets/imgs/smartgoals_logo.png" alt="Smartgoals logo">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" class="justify-content-end" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav>
          <b-nav-item href="https://smartgoals.nl/contact/">Contact Us</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.logo {
  width: 20rem;
}
</style>
