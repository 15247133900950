<template>
  <div>
    <!-- TODO: NAVIGATION MENU LATER-->
    <div class="container">
      <Header/>
    </div>

    <!-- BREADCRUMB -->
    <div v-if="this.$route.name !== 'home'" class="container">
      <b-breadcrumb :items="crumbs"></b-breadcrumb>
    </div>

    <!-- BODY -->
    <div class="container w-100 h-100">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: 'MainLayout',
  components: {
    Header
  },

  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()

      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
          text: path.charAt(0).toUpperCase() + path.slice(1),
        });

        return breadcrumbArray;
      }, [])

      breadcrumbs.unshift({
        path: 'home',
        to: '/',
        text: 'Home'
      });

      return breadcrumbs;
    }
  }
}
</script>
